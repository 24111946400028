@charset "UTF-8";
/*
  Project: Traveling Tiki
  Author: GoodieWebsite
 */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Nunito:wght@400;700&display=swap");
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
@keyframes glow1 {
  from {
    text-shadow: 0 0 2px #fff, 0 0 10px #c51df4, 0 0 15px #c51df4, 0 0 25px #c51df4, 0 0 35px #c51df4, 0 0 45px #c51df4;
  }
  to {
    text-shadow: 0 0 2px #fff, 0 0 15px #c51df4, 0 0 25px #c51df4, 0 0 35px #c51df4, 0 0 45px #c51df4, 0 0 55px #c51df4, 0 0 60px #c51df4;
  }
}
@keyframes glow2 {
  from {
    text-shadow: 0 0 2px #fff, 0 0 5px #15a16f, 0 0 10px #15a16f, 0 0 15px #15a16f, 0 0 20px #15a16f, 0 0 25px #15a16f;
  }
  to {
    text-shadow: 0 0 2px #fff, 0 0 10px #15a16f, 0 0 15px #15a16f, 0 0 25px #15a16f, 0 0 40px #15a16f, 0 0 45px #15a16f, 0 0 50px #15a16f;
  }
}
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 1rem;
}

article {
  font-size: 1.75rem;
  color: #8a8a8a;
  padding: 1.5rem;
}
article h1 {
  color: #deedf9;
  animation: glow1 1s ease-in-out infinite alternate;
  letter-spacing: 2px;
  margin: 0;
  font-size: 4.0625rem;
  margin-bottom: 1rem;
}
article h2,
article h3 {
  color: #deedf9;
  font-size: 3rem;
}
article h3 {
  font-size: 2.5rem;
}
article strong {
  color: #fff;
  font-size: 2rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child::before {
  content: open-quote;
}
blockquote p:last-child::after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas Neue", cursive;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #000;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}
@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #fff;
  text-decoration: underline;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.wpcf7-response-output {
  padding: 1.25rem 2rem !important;
  display: inline-block;
  font-size: 1rem;
  border-radius: 2rem;
  border-color: #c51df4 !important;
  box-shadow: 0 0 50px #c51df4, inset 0 0 30px #c51df4;
}

.wpcf7 {
  position: relative;
  z-index: 1;
}
.wpcf7 label span {
  display: block;
  text-align: left;
  max-width: 280px;
}
@media (max-width: 1023.98px) {
  .wpcf7 {
    text-align: center;
  }
  .wpcf7 label span {
    margin: 0 auto;
  }
}

/*
  All a user has to do to change the calendar size is
  change the font-size on the container and everything
  magically resizes accordingly. Relative units ftw!
*/
.qs-datepicker-container {
  font-size: 1rem;
  font-family: sans-serif;
  color: black;
  position: absolute;
  width: 15.625em;
  display: flex;
  flex-direction: column;
  z-index: 9001;
  user-select: none;
  border: 1px solid gray;
  border-radius: 0.263921875em;
  overflow: hidden;
  background: white;
  box-shadow: 0 1.25em 1.25em -0.9375em rgba(0, 0, 0, 0.3);
}
.qs-datepicker-container * {
  box-sizing: border-box;
}

.qs-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qs-hidden {
  display: none;
}

.qs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  width: 100%;
  height: 100%;
  padding: 0.5em;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
}
.qs-overlay.qs-hidden {
  opacity: 0;
  z-index: -1;
}
.qs-overlay .qs-overlay-year {
  border: none;
  background: transparent;
  border-bottom: 1px solid white;
  border-radius: 0;
  color: white;
  font-size: 0.875em;
  padding: 0.25em 0;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.qs-overlay .qs-close {
  padding: 0.5em;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
.qs-overlay .qs-submit {
  border: 1px solid white;
  border-radius: 0.263921875em;
  padding: 0.5em;
  margin: 0 auto auto;
  cursor: pointer;
  background: rgba(128, 128, 128, 0.4);
}
.qs-overlay .qs-submit.qs-disabled {
  color: gray;
  border-color: gray;
  cursor: not-allowed;
}
.qs-overlay .qs-overlay-month-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.qs-overlay .qs-overlay-month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.15s;
}
.qs-overlay .qs-overlay-month.active, .qs-overlay .qs-overlay-month:hover {
  opacity: 1;
}

.qs-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  background: lightgray;
  filter: blur(0px);
  transition: filter 0.3s;
}
.qs-controls.qs-blur {
  filter: blur(5px);
}

.qs-arrow {
  height: 1.5625em;
  width: 1.5625em;
  position: relative;
  cursor: pointer;
  border-radius: 0.263921875em;
  transition: background 0.15s;
}
.qs-arrow:hover {
  background: rgba(0, 0, 0, 0.1);
}
.qs-arrow:hover.qs-left:after {
  border-right-color: black;
}
.qs-arrow:hover.qs-right:after {
  border-left-color: black;
}
.qs-arrow:after {
  content: "";
  border: 0.390625em solid transparent;
  position: absolute;
  top: 50%;
  transition: border 0.2s;
}
.qs-arrow.qs-left:after {
  border-right-color: gray;
  right: 50%;
  transform: translate(25%, -50%);
}
.qs-arrow.qs-right:after {
  border-left-color: gray;
  left: 50%;
  transform: translate(-25%, -50%);
}

.qs-month-year {
  font-weight: bold;
  transition: border 0.2s;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}
.qs-month-year:hover {
  border-bottom: 1px solid gray;
}
.qs-month-year:focus, .qs-month-year:active:focus {
  outline: none;
}

.qs-month {
  padding-right: 0.5ex;
}

.qs-year {
  padding-left: 0.5ex;
}

.qs-squares {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3125em;
  filter: blur(0px);
  transition: filter 0.3s;
}
.qs-squares.qs-blur {
  filter: blur(5px);
}

.qs-square {
  width: calc(100% / 7);
  height: 1.5625em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.1s;
  border-radius: 0.263921875em;
}
.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
  background: orange;
}

.qs-current {
  font-weight: bold;
  text-decoration: underline;
}

/*
  3 possibilities:
    1. Single, active date.
    2. Daterange start selection.
    3. Daterange end selection.
*/
.qs-active,
.qs-range-start,
.qs-range-end {
  background: lightblue;
}

.qs-range-start:not(.qs-range-6) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.qs-range-middle {
  background: #d4ebf2;
}
.qs-range-middle:not(.qs-range-0):not(.qs-range-6) {
  border-radius: 0;
}
.qs-range-middle.qs-range-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.qs-range-middle.qs-range-6 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.qs-range-end:not(.qs-range-0) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.qs-disabled,
.qs-outside-current-month {
  opacity: 0.2;
}

.qs-disabled {
  cursor: not-allowed;
}

.qs-empty {
  cursor: default;
}

.qs-day {
  cursor: default;
  font-weight: bold;
  color: gray;
}

.qs-event {
  position: relative;
}
.qs-event:after {
  content: "";
  position: absolute;
  width: 0.46875em;
  height: 0.46875em;
  border-radius: 50%;
  background: #07f;
  bottom: 0;
  right: 0;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1480px;
}
.o-wrapper::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.c-about {
  overflow: hidden;
  padding: 4rem 2rem 6rem;
}

@media (min-width: 1024px) {
  .c-about__wrapper {
    display: flex;
    align-items: center;
  }
}

.c-about__text {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 5rem;
  font-size: 1.75rem;
  color: #8a8a8a;
  text-align: center;
}
.c-about__text h1 {
  color: #deedf9;
  animation: glow1 1s ease-in-out infinite alternate;
  letter-spacing: 2px;
  margin: 0;
  font-size: 4.0625rem;
  margin-bottom: 1rem;
}
.c-about__text strong {
  color: #fff;
  font-size: 2rem;
}
@media (min-width: 1024px) {
  .c-about__text {
    width: 50%;
    text-align: left;
    padding-right: 11rem;
  }
}

.c-about__image {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .c-about__image {
    width: 50%;
  }
}

.c-about__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  border: 2px solid #c51df4;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0 0 50px #c51df4, inset 0 0 30px #c51df4;
}

.c-about__image-image {
  border-radius: 30px;
  width: 100%;
}

.c-about__image-deco {
  position: absolute;
  mix-blend-mode: screen;
}
.c-about__image-deco--1 {
  left: -95px;
  bottom: -100px;
  z-index: 2;
  animation: rotateHead 2s ease-in-out infinite alternate;
}
@media (max-width: 569.98px) {
  .c-about__image-deco--1 {
    width: 150px;
    left: -65px;
    bottom: -100px;
  }
}
.c-about__image-deco--2 {
  left: -380px;
  bottom: -200px;
  z-index: 0;
}
@media (max-width: 569.98px) {
  .c-about__image-deco--2 {
    width: 400px;
    left: -160px;
    bottom: -100px;
  }
}
.c-about__image-deco--3 {
  right: -160px;
  bottom: -100px;
  z-index: 2;
}
@media (max-width: 569.98px) {
  .c-about__image-deco--3 {
    right: -80px;
  }
}

@keyframes rotateHead {
  from {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}
/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border-radius: 1rem;
  border: 6px solid #c51df4;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 0 50px #c51df4, inset 0 0 30px #c51df4;
  font-family: "Bebas Neue", cursive;
}
.c-btn:hover {
  box-shadow: 0 0 70px #c51df4, inset 0 0 80px #c51df4;
}
.c-header .c-btn {
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .c-header .c-btn {
    bottom: 50px;
    width: calc(100% - 2rem);
  }
}
.c-cta .c-btn, .c-contact-form .c-btn {
  display: block;
  width: 100%;
  max-width: 510px;
  border-radius: 60px;
  margin: 0 auto;
  border-color: rgba(222, 126, 249, 0.8);
  box-shadow: 0 0 40px rgba(222, 126, 249, 0.8), inset 0 0 50px rgba(222, 126, 249, 0.8);
}
.c-cta .c-btn:hover, .c-contact-form .c-btn:hover {
  box-shadow: 0 0 70px rgba(222, 126, 249, 0.8), inset 0 0 80px rgba(222, 126, 249, 0.8);
}

/* Style variants
   ========================================================================== */
/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  width: 100%;
  max-width: 620px;
  font-size: 3rem;
  border-radius: 60px;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}
.c-comment-form label {
  display: block;
}
.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-contact-form {
  padding: 0 1.5rem;
  padding-top: 14rem;
}
@media (min-width: 1024px) {
  .c-contact-form {
    padding-top: 10rem;
    margin-bottom: -6rem;
  }
}

.c-contact-form__wrapper {
  max-width: 1300px;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .c-contact-form__wrapper {
    display: flex;
    position: relative;
  }
}

@media (min-width: 1024px) {
  .c-contact-form__left,
.c-contact-form__right {
    width: 50%;
  }
}

.c-contact-form__right {
  font-weight: 700;
  position: relative;
}
.c-contact-form__right input[type=text],
.c-contact-form__right input[type=email] {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-weight: 400;
  width: 100%;
}
@media (min-width: 1024px) {
  .c-contact-form__right {
    top: 20px;
    position: sticky;
    align-self: flex-start;
  }
}

.c-contact-form__btn {
  font-size: 2rem;
  background: transparent;
  margin-top: 2rem !important;
  max-width: 360px !important;
}
@media (min-width: 1024px) {
  .c-contact-form__btn {
    margin-left: 0 !important;
  }
}

.c-contact-form__text {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 5rem;
  font-size: 1.4rem;
  color: #8a8a8a;
  text-align: center;
}
.c-contact-form__text h2 {
  position: relative;
  color: #deedf9;
  animation: glow1 1s ease-in-out infinite alternate;
  letter-spacing: 2px;
  margin: 0;
  font-size: 3rem;
  margin-bottom: 1rem;
  z-index: 1;
}
.c-contact-form__text strong {
  color: #fff;
  font-size: 2rem;
}
@media (min-width: 1024px) {
  .c-contact-form__text {
    width: 50%;
    text-align: left;
  }
}

.c-form__price-tag {
  font-family: "Bebas Neue", cursive;
  font-size: 4.375rem;
}
.c-form__price-tag span {
  font-size: 2rem;
}

.c-contact-form__deco {
  position: absolute;
  z-index: 0;
}
.c-contact-form__deco--drink {
  animation: rotateDrink 3s ease-in-out infinite alternate;
  width: 400px;
  top: -200px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .c-contact-form__deco--drink {
    left: -118px;
  }
}
.c-contact-form__deco--sun {
  animation: rotateSun 3s ease-in-out infinite alternate;
  width: 400px;
  bottom: 60px;
  right: 0;
  display: none;
}
@media (min-width: 1024px) {
  .c-contact-form__deco--sun {
    display: block;
  }
}

.c-contact-form__deco-image {
  mix-blend-mode: screen;
}

@keyframes rotateDrink {
  from {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-3deg);
  }
  to {
    transform: rotate(3deg);
  }
}
@keyframes rotateSun {
  from {
    transform: rotate(0);
  }
  50% {
    transform: rotate(3deg);
  }
  to {
    transform: rotate(-3deg);
  }
}
.c-cta {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 0 2rem;
  padding-bottom: 5rem;
}
@media (min-width: 768px) {
  .c-cta {
    padding-bottom: 0;
    height: 913px;
    margin-top: -200px;
  }
}

.c-cta__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.c-cta__content {
  position: relative;
}

.c-cta__icon-img {
  mix-blend-mode: screen;
  display: block;
  margin: 0 auto;
  width: 320px;
  transform: translateY(50px);
  animation: pushGlass 2s ease-in-out infinite alternate;
}
@media (min-width: 1280px) {
  .c-cta__icon-img {
    position: absolute;
    width: 450px;
    left: -340px;
    top: -190px;
  }
}

.c-cta__heading {
  letter-spacing: 8px;
  animation: glow2 1s ease-in-out infinite alternate;
  margin: 0;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 3rem;
}
@media (min-width: 480px) {
  .c-cta__heading {
    font-size: 5rem;
  }
}

.c-cta__btn {
  font-size: 3rem;
}
@media (max-width: 479.98px) {
  .c-cta__btn {
    font-size: 2rem;
  }
}

@keyframes pushGlass {
  from {
    transform: rotate(0);
  }
  60% {
    transform: rotate(3deg);
  }
  to {
    transform: rotate(0);
  }
}
/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #e1a284;
  background-image: url("~assets/images/footer-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  padding: 5.625rem 2rem 12.5rem;
}
@media (max-width: 479.98px) {
  .c-footer {
    padding-bottom: 6rem;
  }
}

.c-footer__wrapper {
  max-width: 1048px;
  margin: 0 auto;
}

.c-footer__content {
  display: flex;
}
@media (max-width: 999.98px) {
  .c-footer__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1000px) {
  .c-footer__col {
    width: 33%;
  }
}
@media (min-width: 1000px) {
  .c-footer__col:first-child {
    text-align: left;
  }
}
.c-footer__col:first-child, .c-footer__col:last-child {
  margin-top: 40px;
}
@media (min-width: 1000px) {
  .c-footer__col:first-child, .c-footer__col:last-child {
    margin-top: 100px;
  }
}
@media (max-width: 999.98px) {
  .c-footer__col:first-child {
    order: 3;
  }
  .c-footer__col:last-child {
    order: 2;
  }
}
@media (max-width: 999.98px) {
  .c-footer__col:nth-child(2) {
    order: 1;
  }
}

.c-footer__copy p {
  margin-bottom: 0;
}

.c-footer__logo {
  width: 220px;
  display: block;
  margin: 0 auto;
}

.c-footer__content-heading {
  font-size: 1.9375rem;
  letter-spacing: 1px;
  color: #f2938f;
}

.c-footer__content-text {
  color: #fff;
  font-family: "Bebas Neue", cursive;
  font-size: 1.25rem;
  letter-spacing: 1px;
}
.c-footer__content-text p {
  margin-bottom: 0.5rem;
}

.c-footer__content-nav {
  font-family: "Bebas Neue", cursive;
  font-size: 1.25rem;
  letter-spacing: 1px;
}
.c-footer__content-nav ul {
  margin: 0;
}
.c-footer__content-nav li {
  list-style-type: none;
}
.c-footer__content-nav a {
  text-decoration: none;
}
.c-footer__content-nav a:hover {
  text-decoration: underline;
}

.c-footer__social-wrapper {
  display: flex;
  justify-content: center;
}

.c-footer__social {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #e1a284;
  margin: 2rem 1rem 3rem;
}
@media (max-width: 479.98px) {
  .c-footer__social {
    width: 50px;
    height: 50px;
  }
}

.c-footer__social-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 35px;
}
@media (max-width: 479.98px) {
  .c-footer__social-icon {
    width: 25px;
  }
}

/* stylelint-disable no-descending-specificity */
.c-gallery {
  position: relative;
  overflow: hidden;
}

.c-gallery__deco {
  position: absolute;
  width: 1000px;
  mix-blend-mode: screen;
  z-index: 0;
  top: -100px;
  left: 0;
}

.c-gallery__wrapper {
  z-index: 1;
  position: relative;
}

.c-gallery__instance {
  display: flex;
  margin: 0 -6px;
  margin-top: 12rem;
  overflow: auto;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .c-gallery__instance {
    margin-top: 6rem;
  }
  .c-gallery__instance > div {
    flex: 0 0 auto;
  }
}

.c-gallery__group1,
.c-gallery__group2,
.c-gallery__group3 {
  margin: 6px;
  width: 55%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
}
@media (min-width: 768px) {
  .c-gallery__group1,
.c-gallery__group2,
.c-gallery__group3 {
    width: 33%;
  }
}
.c-gallery__group1 .c-gallery__item .o-img,
.c-gallery__group2 .c-gallery__item .o-img,
.c-gallery__group3 .c-gallery__item .o-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.c-gallery__group1 .c-gallery__item:first-child {
  grid-column: 1/3;
  grid-row: 1;
}
.c-gallery__group2 .c-gallery__item:first-child {
  grid-column: 1/3;
}
.c-gallery__group3 .c-gallery__item:last-child {
  grid-column: 1/3;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: relative;
  height: 1251px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("~assets/images/header-bg.jpg");
  max-height: 100vh;
}

.c-header__logo {
  max-height: 95vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.c-header__logo-bg {
  mix-blend-mode: screen;
  max-height: 95vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.c-header__logo-group {
  text-align: center;
  display: block;
}

.c-header__wrapper {
  padding: 2rem;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  margin: 0 0 2em 0;
}

.c-main-nav__item {
  font-weight: bold;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;
}

.c-main-nav__link {
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__link {
  border-bottom-color: #000;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}
.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}
.c-post::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}