// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$nunito: 'Nunito', sans-serif;
$bebas: 'Bebas Neue', cursive;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  full: 1600px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #8a8a8a;
$color-medium-grey: #666;
$color-dark-grey: #333;
$color-purple: #c51df4;
$color-light-brown: #e1a284;
$color-light-blue: #deedf9;
$color-green: #15a16f;
$color-pink: #f2938f;

// Text
$color-bg: $color-black;
$color-text: $color-white;

// Links
$color-link: $color-medium-grey;
$color-hover: $color-light-grey;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;

@keyframes glow1 {
  from {
    text-shadow: 0 0 2px #fff, 0 0 10px $color-purple, 0 0 15px $color-purple,
      0 0 25px $color-purple, 0 0 35px $color-purple, 0 0 45px $color-purple;
  }

  to {
    text-shadow: 0 0 2px #fff, 0 0 15px $color-purple, 0 0 25px $color-purple,
      0 0 35px $color-purple, 0 0 45px $color-purple, 0 0 55px $color-purple,
      0 0 60px $color-purple;
  }
}

@keyframes glow2 {
  from {
    text-shadow: 0 0 2px #fff, 0 0 5px $color-green, 0 0 10px $color-green,
      0 0 15px $color-green, 0 0 20px $color-green, 0 0 25px $color-green;
  }

  to {
    text-shadow: 0 0 2px #fff, 0 0 10px $color-green, 0 0 15px $color-green,
      0 0 25px $color-green, 0 0 40px $color-green, 0 0 45px $color-green,
      0 0 50px $color-green;
  }
}
