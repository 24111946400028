/* stylelint-disable no-descending-specificity */

.c-gallery {
  position: relative;
  overflow: hidden;
}

.c-gallery__deco {
  position: absolute;
  width: 1000px;
  mix-blend-mode: screen;
  z-index: 0;
  top: -100px;
  left: 0;
}

.c-gallery__wrapper {
  z-index: 1;
  position: relative;
}

.c-gallery__instance {
  display: flex;
  margin: 0 -6px;
  margin-top: 12rem;
  overflow: auto;
  flex-wrap: nowrap;

  @include bp-down(medium) {
    margin-top: 6rem;

    > div {
      flex: 0 0 auto;
    }
  }
}

.c-gallery__group1,
.c-gallery__group2,
.c-gallery__group3 {
  margin: 6px;
  width: 55%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  @include bp(medium) {
    width: 33%;
  }

  .c-gallery__item {
    .o-img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.c-gallery__item {
  .c-gallery__group1 & {
    &:first-child {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }

  .c-gallery__group2 & {
    &:first-child {
      grid-column: 1 / 3;
    }
  }

  .c-gallery__group3 & {
    &:last-child {
      grid-column: 1 / 3;
    }
  }
}
