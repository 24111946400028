/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  text-align: center;
  font-family: $nunito;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-light-brown;
  background-image: url('~assets/images/footer-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  padding: 5.625rem 2rem 12.5rem;

  @include bp-down(small) {
    padding-bottom: 6rem;
  }
}

.c-footer__wrapper {
  max-width: 1048px;
  margin: 0 auto;
}

.c-footer__content {
  display: flex;

  @include bp-down(1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.c-footer__col {
  @include bp(1000px) {
    width: 33%;
  }

  &:first-child {
    @include bp(1000px) {
      text-align: left;
    }
  }

  &:first-child,
  &:last-child {
    margin-top: 40px;

    @include bp(1000px) {
      margin-top: 100px;
    }
  }

  @include bp-down(1000px) {
    &:first-child {
      order: 3;
    }

    &:last-child {
      order: 2;
    }
  }

  &:nth-child(2) {
    @include bp-down(1000px) {
      order: 1;
    }
  }
}

.c-footer__copy {
  p {
    margin-bottom: 0;
  }
}

.c-footer__logo {
  width: 220px;
  display: block;
  margin: 0 auto;
}

.c-footer__content-heading {
  font-size: 1.9375rem;
  letter-spacing: 1px;
  color: $color-pink;
}

.c-footer__content-text {
  color: $color-white;
  font-family: $bebas;
  font-size: 1.25rem;
  letter-spacing: 1px;

  p {
    margin-bottom: 0.5rem;
  }
}

.c-footer__content-nav {
  font-family: $bebas;
  font-size: 1.25rem;
  letter-spacing: 1px;

  ul {
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.c-footer__social-wrapper {
  display: flex;
  justify-content: center;
}

.c-footer__social {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid $color-light-brown;
  margin: 2rem 1rem 3rem;

  @include bp-down(small) {
    width: 50px;
    height: 50px;
  }
}

.c-footer__social-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 35px;

  @include bp-down(small) {
    width: 25px;
  }
}
