.c-contact-form {
  padding: 0 1.5rem;
  padding-top: 14rem;

  @include bp(large) {
    padding-top: 10rem;
    margin-bottom: -6rem;
  }
}

.c-contact-form__wrapper {
  max-width: 1300px;
  margin: 0 auto;

  @include bp(large) {
    display: flex;
    position: relative;
  }
}

.c-contact-form__left,
.c-contact-form__right {
  @include bp(large) {
    width: 50%;
  }
}

.c-contact-form__right {
  font-weight: 700;
  position: relative;

  input[type='text'],
  input[type='email'] {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid $color-white;
    color: $color-white;
    font-weight: 400;
    width: 100%;
  }

  @include bp(large) {
    top: 20px;
    position: sticky;
    align-self: flex-start;
  }
}

.c-contact-form__btn {
  font-size: 2rem;
  background: transparent;
  margin-top: 2rem !important;
  max-width: 360px !important;

  @include bp(large) {
    margin-left: 0 !important;
  }
}

.c-contact-form__text {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 5rem;

  h2 {
    position: relative;
    color: $color-light-blue;
    animation: glow1 1s ease-in-out infinite alternate;
    letter-spacing: 2px;
    margin: 0;
    font-size: 3rem;
    margin-bottom: 1rem;
    z-index: 1;
  }

  strong {
    color: $color-white;
    font-size: 2rem;
  }

  font-size: 1.4rem;
  color: $color-light-grey;
  text-align: center;

  @include bp(large) {
    width: 50%;
    text-align: left;
  }
}

.c-form__price-tag {
  font-family: $bebas;
  font-size: 4.375rem;

  span {
    font-size: 2rem;
  }
}

.c-contact-form__deco {
  position: absolute;
  z-index: 0;

  &--drink {
    animation: rotateDrink 3s ease-in-out infinite alternate;
    width: 400px;
    top: -200px;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include bp(large) {
      left: -118px;
    }
  }

  &--sun {
    animation: rotateSun 3s ease-in-out infinite alternate;
    width: 400px;
    bottom: 60px;
    right: 0;
    display: none;

    @include bp(large) {
      display: block;
    }
  }
}

.c-contact-form__deco-image {
  mix-blend-mode: screen;
}

@keyframes rotateDrink {
  from {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-3deg);
  }

  to {
    transform: rotate(3deg);
  }
}

@keyframes rotateSun {
  from {
    transform: rotate(0);
  }

  50% {
    transform: rotate(3deg);
  }

  to {
    transform: rotate(-3deg);
  }
}
