article {
  font-size: 1.75rem;
  color: $color-light-grey;
  padding: 1.5rem;

  h1 {
    color: $color-light-blue;
    animation: glow1 1s ease-in-out infinite alternate;
    letter-spacing: 2px;
    margin: 0;
    font-size: 4.0625rem;
    margin-bottom: 1rem;
  }

  h2,
  h3 {
    color: $color-light-blue;
    font-size: 3rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  strong {
    color: $color-white;
    font-size: 2rem;
  }
}
