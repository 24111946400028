/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.5rem 1rem;
  transition: $global-transition;
  border-radius: 1rem;
  border: 6px solid $color-purple;
  color: $color-white;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 0 50px $color-purple, inset 0 0 30px $color-purple;
  font-family: $bebas;

  &:hover {
    box-shadow: 0 0 70px $color-purple, inset 0 0 80px $color-purple;
  }

  .c-header & {
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
    margin: 0 auto;

    @include bp-down(medium) {
      bottom: 50px;
      width: calc(100% - 2rem);
    }
  }

  .c-cta &,
  .c-contact-form & {
    display: block;
    width: 100%;
    max-width: 510px;
    border-radius: 60px;
    margin: 0 auto;
    border-color: rgba(lighten($color-purple, 20%), 0.8);
    box-shadow: 0 0 40px rgba(lighten($color-purple, 20%), 0.8),
      inset 0 0 50px rgba(lighten($color-purple, 20%), 0.8);

    &:hover {
      box-shadow: 0 0 70px rgba(lighten($color-purple, 20%), 0.8),
        inset 0 0 80px rgba(lighten($color-purple, 20%), 0.8);
    }
  }
}

/* Style variants
   ========================================================================== */

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  width: 100%;
  max-width: 620px;
  font-size: 3rem;
  border-radius: 60px;
}
