.wpcf7-response-output {
  padding: 1.25rem 2rem !important;
  display: inline-block;
  font-size: 1rem;
  border-radius: 2rem;
  border-color: $color-purple !important;
  box-shadow: 0 0 50px $color-purple, inset 0 0 30px $color-purple;
}

.wpcf7 {
  position: relative;
  z-index: 1;

  label span {
    display: block;
    text-align: left;
    max-width: 280px;
  }

  @include bp-down(large) {
    text-align: center;

    label span {
      margin: 0 auto;
    }
  }
}
