.c-about {
  overflow: hidden;
  padding: 4rem 2rem 6rem;
}

.c-about__wrapper {
  @include bp(large) {
    display: flex;
    align-items: center;
  }
}

.c-about__text {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 5rem;

  h1 {
    color: $color-light-blue;
    animation: glow1 1s ease-in-out infinite alternate;
    letter-spacing: 2px;
    margin: 0;
    font-size: 4.0625rem;
    margin-bottom: 1rem;
  }

  strong {
    color: $color-white;
    font-size: 2rem;
  }

  font-size: 1.75rem;
  color: $color-light-grey;
  text-align: center;

  @include bp(large) {
    width: 50%;
    text-align: left;
    padding-right: 11rem;
  }
}

.c-about__image {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  @include bp(large) {
    width: 50%;
  }
}

.c-about__image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  border: 2px solid $color-purple;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0 0 50px $color-purple, inset 0 0 30px $color-purple;
}

.c-about__image-image {
  border-radius: 30px;
  width: 100%;
}

.c-about__image-deco {
  position: absolute;
  mix-blend-mode: screen;

  &--1 {
    left: -95px;
    bottom: -100px;
    z-index: 2;
    animation: rotateHead 2s ease-in-out infinite alternate;

    @include bp-down(570px) {
      width: 150px;
      left: -65px;
      bottom: -100px;
    }
  }

  &--2 {
    left: -380px;
    bottom: -200px;
    z-index: 0;

    @include bp-down(570px) {
      width: 400px;
      left: -160px;
      bottom: -100px;
    }
  }

  &--3 {
    right: -160px;
    bottom: -100px;
    z-index: 2;

    @include bp-down(570px) {
      right: -80px;
    }
  }
}

@keyframes rotateHead {
  from {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(5deg);
  }
}
