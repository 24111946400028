/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: relative;
  height: 1251px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('~assets/images/header-bg.jpg');
  max-height: 100vh;
}

.c-header__logo {
  max-height: 95vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.c-header__logo-bg {
  mix-blend-mode: screen;
  max-height: 95vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.c-header__logo-group {
  text-align: center;
  display: block;
}

.c-header__wrapper {
  padding: 2rem;
}
