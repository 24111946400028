.c-cta {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 0 2rem;
  padding-bottom: 5rem;

  @include bp(medium) {
    padding-bottom: 0;
    height: 913px;
    margin-top: -200px;
  }
}

.c-cta__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.c-cta__content {
  position: relative;
}

.c-cta__icon-img {
  mix-blend-mode: screen;
  display: block;
  margin: 0 auto;
  width: 320px;
  transform: translateY(50px);
  animation: pushGlass 2s ease-in-out infinite alternate;

  @include bp(xlarge) {
    position: absolute;
    width: 450px;
    left: -340px;
    top: -190px;
  }
}

.c-cta__heading {
  letter-spacing: 8px;
  animation: glow2 1s ease-in-out infinite alternate;
  margin: 0;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 3rem;

  @include bp(small) {
    font-size: 5rem;
  }
}

.c-cta__btn {
  font-size: 3rem;

  @include bp-down(small) {
    font-size: 2rem;
  }
}

@keyframes pushGlass {
  from {
    transform: rotate(0);
  }

  60% {
    transform: rotate(3deg);
  }

  to {
    transform: rotate(0);
  }
}
